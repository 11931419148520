.container
{
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
}

.top
{
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settings
{
  flex-shrink: 0;
  margin-right: 10px;
}

.section
{
  display: flex;
  flex-direction: row;
}

@media (max-width: 968px)
{
  .section
  {
    flex-direction: column;
    justify-content: space-between;
  }
}


/* effects */
.effectitems
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 10px;
}

.effectitem
{
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  box-sizing: border-box;
  user-select: none;
}
@media (max-width: 968px)
{
  .effectitem
  {
    width: 100%;
  }
}

.effectitemremove
{
  cursor: pointer;
}

.buttons
{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.start
{
  margin-top: 8px;
  flex-grow: 1;
}

.reset
{
  height: 60px;
  margin-top: 8px;
}

/* table */
.tableContainer
{
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 4px;
}

.table
{
  width: 100%;
}

.head
{
  position: sticky;
  top: 0;
  background-color: var(--bg-accent);
}

.body
{
  overflow-y: auto;
  user-select: none;
}

.cell
{
  cursor: pointer;
}

.selected
{
  border: 1px solid grey;
}

.row:nth-child(even)
{
  background-color: var(--bg-accent);
}



.mobile,
.mobile_head
{
  display: none;
}

@media (max-width: 968px)
{
  .desktop
  {
    display: none;
  }

  .mobile
  {
    display: table-row;
  }

  .mobile_head
  {
    display: table-cell;
  }
}
