.container
{
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
}

.top
{
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.search
{
  display: flex;
  flex-direction: row;
}


.tableContainer
{
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 4px;
}
.table
{
  width: 100%;
}

.head
{
  position: sticky;
  top: 0;
  background-color: var(--bg-accent);
}

.sort
{
  user-select: none;
  cursor: pointer;
}
