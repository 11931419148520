.body
{
  margin-left: 200px;
  height: 100vh;
  padding: 4px;
}


@media (max-width: 968px)
{
  .body
  {
    margin-left: 24px;
  }
}
