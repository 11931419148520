.bar
{
  width: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  border-right: 1px solid var(--fg-colour);
  display: flex;
  flex-direction: column;
}

.game
{
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 1px solid var(--fg-colour);
}

.tab
{
  border-bottom: 1px solid var(--fg-colour);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: var(--fg-colour);
  text-decoration: none;
}

.label
{
  width: 100%;
  text-align: center;
}

.container
{
  height: 32px;
  width: 32px;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
}

.icon
{
  font-size: 24px;
  margin: 0 auto;
}


.donate {
  padding: 1em;
  font-size: 1.5em;
  text-align: center;
  background-color: var(--bg-accent);
  text-decoration: none;
  color: var(--fg-colour);
}

.donate:hover {
  background-color: var(--fg-accent);
  cursor: pointer;
  user-select: none;
}


.bottom
{
  margin-top: auto;
}

.privacy
{
  padding: 4px;
  box-sizing: border-box;
}

.version
{
  padding: 4px;
  box-sizing: border-box;
}

.mobile
{
  display: none;
}

@media (max-width: 968px)
{
  .bar
  {
    width: 24px;
  }

  .tab
  {
    width: 24px;
    height: 24px;
  }

  .label
  {
    display: none;
  }

  .icon
  {
    font-size: 16px;
  }

  .bottom
  {
    display: none;
  }

  .desktop
  {
    display: none;
  }
  .mobile
  {
    display: block;
  }

  .donate {
    padding: 0;
  }
}
