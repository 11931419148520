.row:nth-child(even)
{
  background-color: var(--bg-accent);
}


.good
{
  color:#4caf50;
}


.bad
{
  color:#f44336;
}
